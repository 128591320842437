import Field from "@components/shared/Fields";
import { Col, Row, Form, Input, notification, Divider, Modal } from "antd";
import styles from "./styles.module.scss";
import CustomButton from "@components/shared/Button";
import epalletLogo from "@assets/images/epalletLogo2.png";
import Image from "next/image";
import { useState, useContext, useEffect } from "react";
import Link from "next/link";
import axios from "axios";
import { setCookie, removeCookies } from "cookies-next";
import { AppContext } from "~/src/libs/context";
import { useSession, signIn } from "next-auth/react";
import Icon from "src/components/shared/Icon";
import { useRouter } from "next/router";
import { getText } from "@src/libs/resources";
import epalletLoginProps from "./types";
import SEO from "../../shared/SEO";
import googleLogo from "@assets/images/google_normal.png";
import { getCookie } from "~/src/server/utils/commonUtils";

const EpalletLogin: React.FC<epalletLoginProps> = ({ sourcePath }) => {
  const { guestUser, setGuestUser, completeProfileLink } = useContext<any>(AppContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [guestUserState, setGuestUserState] = useState(false);
  const [forgetPasswordMode, setForgetPasswordMode] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const router = useRouter();
  const [form] = Form.useForm();

  const { data: session } = useSession();
  useEffect(() => {
    if (session) {
      onFinish({});
    }
  }, [session]);
  useEffect(() => {
    if (!guestUser)
      window.location.href = sourcePath
        ? encodeURI(sourcePath?.replace("__", "&"))
        : "/";
    else {
      setGuestUserState(true);
    }
  }, []);
  const onFinish = async (values: any) => {
    setBtnLoading(true);
    if (forgetPasswordMode) {
      const restoreEmail = {
        email: values?.Email,
      };
      try {
        setErrorMessage("");
        await axios.post(
          `/api/ERP/request-link?type=RESTORE_PASS`,
          restoreEmail
        );
        notification.success({
          message: getText().Login.messages.passwordResetLinkSend,
          duration: 4,
        });
      } catch (error: any) {
        if (error.response && error.response.status === 429) {
          notification.error({
            message: 'Too Many Requests',
            description: 'You have made too many requests. Please try again later.',
            duration: 4,
          });
        }
        else {
          let errorMessage;
          if (error?.response?.data?.detail?.includes("Not found")) {
            errorMessage = getText().Login.messages.emailUnRegistered;
          }
          notification.error({
            message: 'Error',
            description: `${
              errorMessage ||
              error?.response?.data?.detail ||
              getText().General.Messages.SomethingWrongTryAgain
            }`,
            duration: 4,
          });
        }
      }
    } else {
      const credentials = session
        ? {
            access_token: (session as any)?.accessToken,
            provider: "google-oauth2",
          }
        : {
            email: values?.Email,
            password: values?.Password,
            beta_login: true,
          };
      try {
        let isNewUser = false;
        let isVendor = false;

        setErrorMessage("");
        const response = await axios.post(
          session ? `/api/social-auth` : `/api/epallet-login/login`,
          credentials
        );
        if (response) {
          try {
            await axios
              .get("/api/ERP/customer/status", {
                headers: { sessionid: getCookie("sessionid") },
              })
              .then((response) => {
                setCookie("userID", response?.data?.user_id, {
                  path: "/",
                  sameSite: "strict",
                  secure: true,
                });
              });
          } catch (e) {}
          window?.dataLayer?.push({
            event: "login",
            method: session ? "Google" : "Email",
            status: "login_attempt",
            message: "Attempt",
          });
          window?.dataLayer?.push({
            event: "login",
            method: session ? "Google" : "Email",
            status: "login_success",
            message: "success",
          });
        }
        const isSessionValid =
          process?.env?.NEXT_PUBLIC_APP_ENV === "development"
            ? "staging_valid_session"
            : "valid_session";
          const ePalletDomain = process?.env?.NEXT_PUBLIC_PARENT_DOMAIN
        removeCookies(isSessionValid, {
          path: "/",
          domain: ePalletDomain,
        });
        setCookie(isSessionValid, "valid", {
          path: "/",
          httpOnly: false,
          secure: true,
          domain: ePalletDomain,
        });

        setCookie("beta_cookie", true, {
          path: "/",
          sameSite: "strict",
          secure: true,
          domain: ePalletDomain,
        });
        setGuestUser(false);
        isVendor = response?.data?.response?.is_vendor;
        isNewUser = !response?.data?.response?.profile_completed;
        notification.success({
          message: `Welcome Back`,
          duration: 4,
        });
        const redirectionUrl = localStorage.getItem("redirectionUrl") || "/";
        window.location.href = isVendor
          ? (process.env.NEXT_PUBLIC_VENDOR_URL as string)
          : isNewUser
          ? completeProfileLink
          : encodeURI(
              sourcePath ? sourcePath?.replace("__", "&") : redirectionUrl
            );
      } catch (error) {
        window?.dataLayer?.push({
          event: "login",
          method: session ? "Google" : "Email",
          status: "login_attempt",
          message: "Attempt",
        });
        window?.dataLayer?.push({
          event: "login",
          method: session ? "Google" : "Email",
          status: "login_failure",
          message: "fail",
        });

        setErrorMessage(getText().Login.messages.unableToSignIn);
      }
    }
    setBtnLoading(false);
  };
  const handleGoogleSignIn = async () => {
    await signIn("google");
  };
  const showSignUpModal = () => {
    setIsSignUpModalVisible(true);
  };

  const handleSignUpModalCancel = () => {
    setIsSignUpModalVisible(false);
  };
  const onFinishFailed = (errorInfo: any) => {};
  return (
    <>
      <SEO
        title={getText().Login.SEO.title}
        description={getText().Login.SEO.description}
      ></SEO>
      {guestUserState ? (
        <Row
          className={`bg-white text-oxfordBlue container-fluid p-0 ${styles["outer-container"]}`}
        >
          <Col
            lg={{ span: 0 }}
            md={{ span: 2 }}
            sm={{ span: 2 }}
            xs={{ span: 2 }}
          />
          <Col
            lg={{ span: 0 }}
            md={{ span: 22 }}
            sm={{ span: 22 }}
            xs={{ span: 22 }}
            className={`mt-8`}
          >
            <Link href="/">
              <a>
                <Image
                  src={epalletLogo}
                  width="126"
                  height="46"
                  alt="ePallet-logo"
                />
              </a>
            </Link>
          </Col>
          <Col
            lg={{ span: 10 }}
            md={{ span: 0 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
            className={`${styles.left_container} px-0`}
          >
            <Row className={styles.imageContainer}>
              <Col span={10} className={`pt-26`}>
                <Link href="/">
                  <a>
                    <Image
                      src={epalletLogo}
                      width="184"
                      height="67"
                      alt="ePallet-logo"
                      className={styles["cursor-pointer"]}
                    />
                  </a>
                </Link>
                <div
                  className={`${styles.yourSupply} font-bolder-xxl mt-32 lh-sm`}
                >
                  {getText()?.General?.chain}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            lg={{ span: 10 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 20 }}
            className={`mx-auto my-24 ${styles["form-container"]}`}
          >
            <Form
              onFinish={onFinish}
              initialValues={{ remember: true }}
              onFinishFailed={onFinishFailed}
              onChange={() => {
                if (errorMessage) setErrorMessage("");
              }}
              form={form}
            >
              <Row gutter={16}>
                <Col
                  className={`gutter-row ${styles["sign-in-label"]}`}
                  span={24}
                >
                  <span className={`${styles["login-title"]} lh-sm`}>
                    {forgetPasswordMode ? "Forgot Password?" : "Sign in"}
                  </span>
                </Col>
                <Col className={`gutter-row mb-16`} span={24}>
                  <span className={"font-regular-base lh-lg me-40"}>
                    {forgetPasswordMode
                      ? "Please enter your email here. We will send you a restoration link"
                      : "Get more and register to get the best wholesale pricing and product selection"}
                  </span>
                </Col>
                <Col className={`gutter-row`} span={24}>
                  <Form.Item
                    name="Email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input a valid email.",
                      },
                    ]}
                  >
                    <Field
                      placeholder="Email"
                      className={`${styles.border_radius} p-5`}
                    />
                  </Form.Item>
                </Col>
                {forgetPasswordMode ? (
                  ""
                ) : (
                  <>
                    <Col className={`gutter-row`} span={24}>
                      <Form.Item
                        name="Password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          className={`${styles.border_radius} p-5`}
                          placeholder="Password"
                          iconRender={(visible) =>
                            visible ? (
                              <Icon
                                name="eye"
                                color="#BCBCBC"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <Icon
                                name="eye"
                                color="#02254a"
                                width={24}
                                height={24}
                              />
                            )
                          }
                        />
                      </Form.Item>
                      <div className={`text-discountColor`}>{errorMessage}</div>
                    </Col>

                    <Col className={`gutter-row mb-14`} span={24}>
                      <CustomButton
                        text="Forgot password?"
                        disabled={false}
                        theme="link"
                        className={`${styles.ml_auto} font-bold-sm lh-sm `}
                        onClick={() => {
                          form?.resetFields();
                          setErrorMessage("");
                          setForgetPasswordMode(true);
                        }}
                      />
                    </Col>
                  </>
                )}
                <Col className={`gutter-row `} span={24}>
                  <CustomButton
                    className={`${styles.height_50} w-100`}
                    htmlType="submit"
                    theme="primary"
                    disabled={false}
                    loading={btnLoading}
                  >
                    <span className="font-bold-base lh-sm ">
                      {forgetPasswordMode
                        ? getText()?.Login?.resetPassword
                        : getText()?.Login?.signIn}
                    </span>
                  </CustomButton>
                </Col>
                {forgetPasswordMode ? (
                  <Col className={`${styles["text-align"]} mt-34 `} span={24}>
                    <span className="font-regular-base lh-md me-3">
                      {getText()?.Login?.backTo}
                    </span>

                    <CustomButton
                      theme="link"
                      onClick={() => {
                        form.resetFields();
                        setForgetPasswordMode(false);
                      }}
                    >
                      <span className="font-bold-base lh-md ">
                        {getText().General.SignIn}
                      </span>
                    </CustomButton>
                  </Col>
                ) : (
                  <>
                    <Col className={"mt-20"} span={24}>
                      <Divider type="horizontal" className="m-0">
                        OR
                      </Divider>
                    </Col>
                    <Col className={`gutter-row`} span={24}>
                      <div
                        onClick={() => handleGoogleSignIn()}
                        className={`${styles["google-icon-container"]}`}
                      >
                        <Image objectFit="contain" src={googleLogo} />
                      </div>
                    </Col>
                    <Col
                      className={`${styles["text-align"]} mt-34 font-regular-sm lh-lg`}
                      span={24}
                    >
                      <div className={styles["sign-up-container"]}>
                        {getText().Login.DoNotHaveAnAccount}
                        <CustomButton
                          className={`${styles["sign-up"]} ms-2`}
                          theme="link"
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.preventDefault();
                            showSignUpModal();
                          }}
                        >
                          <span className="font-bold-base">
                            {getText().Login.SignUp}
                          </span>
                        </CustomButton>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
      ) : null}
      <Modal
        open={isSignUpModalVisible}
        onCancel={handleSignUpModalCancel}
        footer={null}
        width="40%"
        style={{ top: 20 }}
        bodyStyle={{ overflow: "auto", maxHeight: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <iframe
          src="https://share.hsforms.com/1qGdz9GjYTf-3jraATRlbkwdasgk"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="yes"
          style={{ minHeight: "500px", height: "70vh", width: "100%" }}
        ></iframe>
      </Modal>
    </>
  );
};
export default EpalletLogin;
