import Field from "@components/shared/Fields";
import { Col, Row, Form, Checkbox, Radio, Modal } from "antd";
import Select from "@components/shared/Select";
import styles from "./style.module.scss";
import CustomButton from "@components/shared/Button";
import epalletLogo from "@assets/images/epalletLogo2.png";
import Image from "next/image";
import Icon from "@components/shared/Icon";
import { useState, useContext, useEffect } from "react";
import SearchLocationInput from "src/components/shared/AutocompleteAddress";
import { AppContext } from "@src/libs/context";
import Script from "next/script";
import { getCookie } from "src/server/utils/commonUtils";
import { getText } from "@src/libs/resources";
import { RuleObject } from "antd/es/form";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import Link from "next/link";
import { getTruckSizesRestrictionsOptions } from "src/server/utils/commonUtils";
import useClient from "~/src/server/utils/client";
import HaggleTable from "../shared/HaggleTable";
import { normalizePhoneNumber, phoneValidator } from "~/src/libs/utils/phoneValidator";
import DataLoader from "../shared/DataLoader";

interface GeneralInformationProps {
  token?: any;
  uid?: any;
  haggleTableProps?: any;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  token,
  uid,
  haggleTableProps,
}) => {
  const [form] = Form.useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [joinHaggle, setJoinHaggle] = useState<boolean>(true);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);
  const [customerTypes, setCustomerTypes] = useState<any[]>();
  const [checkedValue, setCheckedValue] = useState<number>();
  const [checkedValueReferral,setCheckedValueReferral] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {
    googlePlacesScript,
    setGooglePlacesScript,
    commonStates,
    commonCountries,
    customerStatus,
  } = useContext<any>(AppContext);
  const client = useClient({});
  const [addressValid, setAddressValid] = useState<boolean>(true);
  const [shippingAddressValid, setShippingAddressValid] =
    useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [referralOtherOption,setReferralOtherOption] = useState<boolean>(false);
  const windowWidth = useWindowResize();
  const referralSource = ["Google/Search Engine", "Social Media", "Peer Referral", "Vendor Referral", "Other (please specify)"]
  const checkIfProfileCompleted = async () => {
    try {
      const response = await client.basic.get("/api/ERP/customer/status", {
        headers: { sessionid: getCookie("sessionid") },
      });
      if (!response?.data?.email_verified) {
        try {
          await client.basic.post("/api/ERP/emailVerification", {
            headers: { sessionId: getCookie("sessionid") },
            data: { uid: uid, token: token },
          });
        } catch { }
      }
      if (
        response?.data?.profile_completed ||
        !response?.data?.is_authenticated
      ) {
        window.location.href = "/";
      } else {
        setIsProfileCompleted(false);
      }
    } catch (error: any) {
      setIsProfileCompleted(false);
    }
  };
  const getCustomerTypes = async () => {
    try {
      const response = await client.basic.get("/api/ERP/customer/types", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setCustomerTypes(response?.data);
    } catch (error: any) { }
  };
  useEffect(() => {
    checkIfProfileCompleted();
    getCustomerTypes();
  }, []);


  const onFinish = async (values: any) => {
    const reqData = {
      contact_name: values?.contact_name,
      agree_with_customer_terms: agreeTerms,
      company_name: values?.company_name,
      title: values?.title,
      company_email: values?.email,
      billing_address: values?.address,
      billing_country: values?.country,
      billing_city: values?.city,
      billing_state: values?.state,
      billing_post_zip: values?.post_zip,
      billing_company_name: values?.company_name,
      is_reseller: values?.is_reseller,
      customer_type: checkedValue,
      join_haggle: joinHaggle,
      shipping_address: {
        address: values?.shipping_address,
        country: values?.shipping_country,
        state: values?.shipping_state,
        post_zip: values?.shipping_post_zip,
        city: values?.shipping_city,
        phone_number: values?.phone_number,
        facility_contact_name: values?.facility_contact_name,
        facility_contact_email: values?.facility_contact_email,
        truck_size_restrictions: values?.truck_size_restrictions,
      },
      referral_source: referralOtherOption ? values?.referral_source_option : values?.referral_source ,

    };
    try {
      setButtonLoader(true);
      await client.basic.post(
        "/api/ERP/customer/profile/customerProfileComplete",
        {
          data: reqData,
        },
        {
          headers: { sessionid: getCookie("sessionid") },
        }
      );

      setButtonLoader(false);
      setIsFormSubmitted(true);

      // Add 'cprofile=finish' parameter to the URL after successful form submission
      const url = new URL(window.location.href);
      url.searchParams.set("cprofile", "finish");
      window.history.pushState({}, "", url.toString());
     
    } catch (error: any) {
      setButtonLoader(false);
      setErrorMessage(error?.response?.data);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    setIsFormSubmitted(false);
  };

  const handleChange = (checkedValues: any) => {
    setCheckedValue(checkedValues?.target?.value);
    form.setFieldValue(
      "customer_type",
      customerTypes?.find((item) => item?.id === checkedValues?.target?.value)
        ?.name
    );
    form.validateFields(["customer_type"]);
  };

  const handleChangeReferral = (checkedValues: any) => {
    if (checkedValues.target.value == referralSource[4]){
      setReferralOtherOption(true);
      setCheckedValueReferral(checkedValues?.target?.value);
      
    }else{
      setReferralOtherOption(false);
      setCheckedValueReferral(checkedValues?.target?.value);
    };
    form.setFieldValue(
      "referral_source",
      checkedValues?.target?.value
    );
    form.validateFields(["referral_source"]);
  };

  const handleSelectLocation = (value: any) => {
    form.setFieldValue("state", value?.state);
    form.setFieldValue("city", value?.city);
    form.setFieldValue("post_zip", value?.zipCode);
    form.setFieldValue("country", value?.country);
    form.setFieldValue("address", value?.formatted_address);
    form.validateFields(["state", "city", "post_zip", "address", "country"]);
  };
  const handleShippingSelectLocation = (value: any) => {
    form.setFieldValue("shipping_state", value?.state);
    form.setFieldValue("shipping_city", value.city);
    form.setFieldValue("shipping_address", value?.formatted_address);
    form.setFieldValue("shipping_post_zip", value?.zipCode);
    form.setFieldValue("shipping_country", value?.country);
    form.validateFields([
      "shipping_state",
      "shipping_city",
      "shipping_address",
      "shipping_post_zip",
      "shipping_country",
    ]);
  };
  const addressValidation = (
    _: RuleObject,
    value: string,
    fieldName: string
  ) => {
    if (fieldName === "address" && !value) {
      setAddressValid(false);
      return Promise.reject("This field should not be empty");
    } else if (fieldName === "address" && value) {
      setAddressValid(true);
      return Promise.resolve();
    } else if (fieldName === "shipping_address" && !value) {
      setShippingAddressValid(false);
      return Promise.reject("This field should not be empty");
    } else if (fieldName === "shipping_address" && value) {
      setShippingAddressValid(true);
      return Promise.resolve();
    }
    return Promise.resolve();
  };

  const locationValidation = (
    _: RuleObject,
    fieldName: string,
    value: string
  ) => {
    let billingKeys = Object?.keys(errorMessage);
    if (billingKeys?.includes("billing_" + fieldName)) {
      let message = errorMessage["billing_" + fieldName];
      delete errorMessage["billing_" + fieldName];
      return Promise.reject(message || `Invalid ${fieldName}`);
    }

    if (errorMessage?.shipping_address && fieldName?.includes("shipping")) {
      let keys = Object?.keys(errorMessage?.shipping_address);
      if (keys?.includes(fieldName.split("-")[1])) {
        let message = errorMessage?.shipping_address[fieldName?.split("-")[1]];
        delete errorMessage?.shipping_address[fieldName?.split("-")[1]];
        return Promise.reject(message || `Invalid ${fieldName}`);
      }
    } else if (!value) {
      return Promise.reject("This field should not be empty");
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    form.validateFields([
      "shipping_state",
      "shipping_city",
      "shipping_address",
      "shipping_post_zip",
      "shipping_country",
      "state",
      "city",
      "post_zip",
      "country",
      "address",
    ]);
  }, [errorMessage]);

  const onFieldsChange = (Fields: any) => {
    if (Fields?.length === 1 && errorMessage) {
      if (
        Fields[0]?.name[0]?.includes("shipping") &&
        errorMessage?.shipping_address
      ) {
        let fieldName =
          Fields[0]?.name[0] === "shipping_post_zip"
            ? "post_zip"
            : Fields[0]?.name[0].split("_")[1];
        delete errorMessage?.shipping_address[fieldName];
        if (Object.keys(errorMessage?.shipping_address).length === 0)
          delete errorMessage?.shipping_address;
      } else {
        delete errorMessage["billing_" + Fields[0]?.name[0]];
      }
    }
    const hasError = Fields.some((field: any) => field.errors?.length > 0);
    setButtonDisabled(hasError);
  };

  return !isProfileCompleted ? (
    <Row className="text-oxfordBlue container-fluid p-0 bg-white color-black">
      <Col
        lg={{ span: 0 }}
        md={{ span: 2 }}
        sm={{ span: 2 }}
        xs={{ span: 2 }}
      />
      <Col
        lg={{ span: 0 }}
        md={{ span: 22 }}
        sm={{ span: 22 }}
        xs={{ span: 22 }}
        className={`mt-8`}
      >
        <Image src={epalletLogo} width="126" height="46" alt="ePallet-logo" />
      </Col>
      <Col
        lg={{ span: 10 }}
        md={{ span: 0 }}
        sm={{ span: 0 }}
        xs={{ span: 0 }}
        className={`${styles.left_container} px-0`}
      >
        <Row className={styles.imageContainer}>
          <Col span={10} className={`pt-26`}>
            <Link href="/">
              <a aria-label="navigate to home page">
                <Image
                  src={epalletLogo}
                  width="184"
                  height="67"
                  alt="ePallet-logo"
                  className={styles["cursor-pointer"]}
                />
              </a>
            </Link>
            <div className={`${styles.yourSupply} font-bolder-xxl mt-32 lh-sm`}>
              {getText()?.General?.chain}
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={2}></Col>
      <Col
        lg={{ span: 10 }}
        md={{ span: 20 }}
        sm={{ span: 20 }}
        xs={{ span: 20 }}
        className={`${styles["form-container"]} py-24 my-24`}
      >
        {!isFormSubmitted ? (
          <Form
            onFinish={onFinish}
            initialValues={{ remember: true }}
            onFinishFailed={onFinishFailed}
            form={form}
            className={styles["general-information-form"]}
            onFieldsChange={onFieldsChange}
          >
            <Row gutter={16}>
              <Col className={`gutter-row `} span={24}>
                <div
                  className={`${styles["general-info-title"]} font-bolder-lg mb-12`}
                >
                  {getText()?.GeneralInformation?.information}
                </div>
              </Col>
              <Col className={`gutter-row`} span={24}>
                <div
                  className={` ${styles["general-info-descritption"]} mb-4 font-bold-h4`}
                >
                  {getText()?.GeneralInformation?.generalCompanyInformation}
                </div>
              </Col>
              <Col className={`gutter-row mb-12`} span={24}>
                {windowWidth >= 992 ? (
                  <span className={`font-regular-base`}>
                    {getText()?.GeneralInformation?.completeYourProfile}
                  </span>
                ) : (
                  <span className={`font-regular-sm`}>
                    {getText()?.GeneralInformation?.completeYourProfileMobile}
                  </span>
                )}
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="customer_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select your business type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Business Type"
                    className={"knowledgeHubSelect"}
                    placement="bottomRight"
                    dropdownMatchSelectWidth={false}
                    popupClassName={`${styles.dropMenuStyle} mt-10`}
                    showArrow={true}
                    dropdownRender={() => (
                      <ul className={`${styles.list_style_type} p-12 m-0`}>
                        {customerTypes?.map(
                          (item: any, index: number) =>
                            item?.name !== "test" && (
                              <li
                                key={index}
                                className={windowWidth < 992 ? "mb-12" : "mb-8"}
                              >
                                <Checkbox
                                  value={item?.id}
                                  onChange={handleChange}
                                  checked={checkedValue === item?.id}
                                >
                                  <span className="font-medium-base">
                                    {item?.name}
                                  </span>
                                </Checkbox>
                              </li>
                            )
                        )}
                      </ul>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="contact_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    placeholder="Contact Name"
                    label="Contact Name"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    placeholder="Company Name"
                    label="Company Name"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    placeholder="Title"
                    label="Title"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col className={`gutter-row`} span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "This field should not be empty." },
                    { type: "email", message: "Please input a valid email." }
                  ]}
                >
                  <Field
                    placeholder="Company Email"
                    label="Email"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                {!googlePlacesScript && (
                  <Script
                    src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLEACES_KEY}&libraries=places`}
                    onLoad={() => {
                      setGooglePlacesScript(true);
                    }}
                  />
                )}
                <Form.Item
                  name="address"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        addressValidation(_, value, "address"),
                    }),
                  ]}
                >
                  {googlePlacesScript && (
                    <SearchLocationInput
                      className={"border-radius-zero"}
                      placeHolder={"Address"}
                      extraAction={handleSelectLocation}
                      name="currentLocation"
                      types={["address"]}
                      isBilling={false}
                      validInput={addressValid}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 0 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="city"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "city", value),
                    }),
                  ]}
                >
                  <Field
                    placeholder="City"
                    label="City"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <Form.Item
                  name="post_zip"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "post_zip", value),
                    }),
                  ]}
                >
                  <Field
                    placeholder="Zip Code"
                    label="Zip Code"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <Form.Item
                  name="state"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "state", value),
                    }),
                  ]}
                >
                  <Select
                    placeholder="State"
                    className={"knowledgeHubSelect"}
                    options={commonStates}
                    showSearch={true}
                    filterOption={(input: string, option: any) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 0 }}
                sm={{ span: 0 }}
                xs={{ span: 0 }}
              >
                <Form.Item
                  name="city"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "city", value),
                    }),
                  ]}
                >
                  <Field
                    placeholder="City"
                    label="City"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row `}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name={"country"}
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "country", value),
                    }),
                  ]}
                >
                  <Select
                    placeholder="Country"
                    className={"knowledgeHubSelect"}
                    options={commonCountries}
                  />
                </Form.Item>
              </Col>
              <Col className={`gutter-row mb-8`} span={24}>
                <span
                  className={
                    windowWidth >= 992 ? "font-medium-base" : "font-medium-sm"
                  }
                >
                  {getText()?.GeneralInformation?.attestMessage}
                </span>
              </Col>
              <Col
                className={
                  windowWidth >= 992 ? "gutter-row mb-8" : "gutter-row mb-2"
                }
                span={24}
              >
                <Form.Item name={"is_reseller"}>
                  <Radio.Group>
                    <Radio value={true}>
                      {getText()?.GeneralInformation?.yesAnswer}
                    </Radio>
                    <Radio value={false}>
                      {getText()?.GeneralInformation?.noAnswer}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col className={`gutter-row `} span={24}>
                <div
                  className={`${styles["general-info-title"]} font-bolder-lg mb-4`}
                >
                  {getText()?.GeneralInformation?.deliveryAddress}
                </div>
              </Col>
              {windowWidth >= 992 ? (
                <Col className={`gutter-row mb-12`} span={15}>
                  <span className={`font-regular-base`}>
                    {getText()?.GeneralInformation?.enterYourDeliveryDetails}
                  </span>
                </Col>
              ) : (
                <Col className={`gutter-row mb-12`} span={24}>
                  <span className={`font-regular-xs`}>
                    {
                      getText()?.GeneralInformation
                        ?.enterYourDeliveryDetailsMobile
                    }
                  </span>
                </Col>
              )}
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="truck_size_restrictions"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Truck size restrictions"
                    options={getTruckSizesRestrictionsOptions()}
                    className={"knowledgeHubSelect"}
                    getPopupContainer={(trigger: any) => trigger?.parentElement}
                  ></Select>
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="phone_number"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        phoneValidator(_, value, "phone_number"),
                    }),
                  ]}
                  normalize={normalizePhoneNumber}
                >
                  <Field
                    placeholder="Facility phone number"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col className={`gutter-row `} span={24}>
                <Form.Item
                  name="facility_contact_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    placeholder="Facility contact name"
                    label="Facility contact name"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                span={24}
              >
                <Form.Item
                  name="facility_contact_email"
                  rules={[
                    { required: true, message: "This field should not be empty." },
                    { type: "email", message: "Please input a valid email." }
                  ]}
                >
                  <Field
                    placeholder="Facility email"
                    label="Facility email"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="shipping_address"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        addressValidation(_, value, "shipping_address"),
                    }),
                  ]}
                >
                  {googlePlacesScript && (
                    <SearchLocationInput
                      className={"border-radius-zero"}
                      placeHolder={"Address"}
                      extraAction={handleShippingSelectLocation}
                      name="currentLocation"
                      types={["address"]}
                      isBilling={false}
                      validInput={shippingAddressValid}
                    ></SearchLocationInput>
                  )}
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row bm-12`}
                lg={{ span: 0 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="shipping_city"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "shipping-city", value),
                    }),
                  ]}
                >
                  <Field
                    placeholder="City"
                    label="shipping_city"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row `}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <Form.Item
                  name="shipping_post_zip"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "shipping-post_zip", value),
                    }),
                  ]}
                >
                  <Field
                    placeholder="Zip Code"
                    label="Zip Code"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 6 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <Form.Item
                  name="shipping_state"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "shipping-state", value),
                    }),
                  ]}
                >
                  <Select
                    placeholder="State"
                    className={"knowledgeHubSelect"}
                    options={commonStates}
                    showSearch={true}
                    filterOption={(input: string, option: any) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                className={`gutter-row bm-12`}
                lg={{ span: 12 }}
                md={{ span: 0 }}
                sm={{ span: 0 }}
                xs={{ span: 0 }}
              >
                <Form.Item
                  name="shipping_city"
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "shipping-city", value),
                    }),
                  ]}
                >
                  <Field
                    placeholder="City"
                    label="shipping_city"
                    className={styles["border-radius-height"]}
                  />
                </Form.Item>
              </Col>
              <Col
                className={
                  windowWidth >= 992 ? "gutter-row mb-6" : "gutter-row "
                }
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name={"shipping_country"}
                  rules={[
                    () => ({
                      validator: (_, value) =>
                        locationValidation(_, "shipping-country", value),
                    }),
                  ]}
                >
                  <Select
                    placeholder="Country"
                    className={"knowledgeHubSelect"}
                    options={commonCountries}
                  />
                </Form.Item>
              </Col>
              <Col className={`gutter-row`} span={24}>
                <div
                  className={` ${styles["general-info-descritption"]} mb-6 font-bold-h6`}
                >
                  {getText()?.GeneralInformation?.howDidYouHearAboutUs}
                </div>
              </Col>
              <Col
                className={`gutter-row`}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Form.Item
                  name="referral_source"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                    ({getFieldValue}) => ({
                      validator(_,value){
                        if ( value == referralSource[4] && !getFieldValue("referral_source_option")){
                          return Promise.reject("This field should not be empty")
                        } else if ( value == referralSource[4] && getFieldValue("referral_source_option").length > 255){
                          return Promise.reject("This field should not have more than 255 characters");
                        }else{
                          return Promise.resolve();
                        }
                      }
                    })
                  ]}
                >
                  <Select
                    placeholder="Choose your option"
                    className={"knowledgeHubSelect"}
                    placement="bottomLeft"
                    dropdownMatchSelectWidth={false}
                    popupClassName={`${styles.circular_checkbox} mt-10`}
                    showArrow={true}
                    dropdownRender={() => (
                      <ul className={`${styles.list_style_type} p-12 m-0`}>
                        {referralSource?.map(
                          (item: string, index: number) =>
                            <li
                          key={index}

                        >
                          <Radio value={item}
                            onChange={handleChangeReferral}
                            checked={checkedValueReferral === item}>
                            <span className="font-medium-base">
                              {item}
                              {checkedValueReferral === item}
                            </span>
                          </Radio>


                        </li>
                        )}
                        {
                          referralOtherOption ? <Form.Item
                          name="referral_source_option"
                          className={styles["other-option"]}
                          rules={[
                            ({getFieldValue}) => ({
                              validator(_,value){
                                if ( !value && getFieldValue("referral_source") == referralSource[4]){
                                  form.validateFields(["referral_source"]);
                                  return Promise.reject("This field should not be empty")
                                 
                                }else{
                                  return Promise.resolve();
                                }
                              }
                            })
                            
                          ]}
                          
                      
                        >
                          <Field
                            placeholder="Enter here"
                            className={styles["border-radius-height"]}
                          />
                        </Form.Item> : <></>
                        }
                        
                      </ul>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            {!customerStatus?.is_vendor ? (
              <Row className={windowWidth >= 992 ? "mb-2" : " mb-6"}>
                <Form.Item name={"join_haggle"}>
                  <Checkbox
                    value={joinHaggle}
                    defaultChecked
                    onChange={(e: any) => setJoinHaggle(e.target.checked)}
                  />
                  <span className={`font-bold-base ms-4`}>
                    <span className="font-bold-base">
                      {getText()?.Haggle?.joinHaggleRewards}
                    </span>
                    <CustomButton
                      text="Learn More"
                      className={`${styles["height-23"]} font-bold-base lh-sm ms-2`}
                      disabled={false}
                      theme="link"
                      onClick={() => setIsModalOpen(true)}
                    />
                  </span>
                  <p className="pt-6 mb-0 ps-14">
                    <span>{getText()?.Haggle?.earnRewards}</span>
                    <span className="font-bold-base">
                      {getText()?.Haggle?.forFree}
                    </span>
                  </p>
                  <p className="font-medium-base mb-3 ps-14">
                    {getText()?.Haggle?.agreeHaggle}
                  </p>
                </Form.Item>
              </Row>
            ) : (
              <Row className={windowWidth >= 992 ? "mb-2" : " mb-6"}>
                <Form.Item name={"agree_with_customer_terms"}>
                  <Checkbox
                    value={agreeTerms}
                    onChange={(e: any) => setAgreeTerms(e.target.checked)}
                  />
                  <span className={`font-medium-base ms-4`}>
                    {getText()?.General?.terms}
                    <CustomButton
                      text={` terms and conditions`}
                      className={`${styles["height-23"]} font-bold-base lh-sm ms-2`}
                      disabled={false}
                      theme="link"
                      href="/terms-and-conditions"
                    />
                  </span>
                </Form.Item>
              </Row>
            )}
            <Row>
              <CustomButton
                className={`${styles["border-radius-height"]} w-100`}
                htmlType="submit"
                theme="primary"
                disabled={
                  !customerStatus?.is_vendor ? buttonDisabled : !agreeTerms
                }
                loading={buttonLoader}
              >
                <span className="font-bold-base">
                  {getText()?.GeneralInformation?.saveAndContinue}
                </span>
              </CustomButton>
            </Row>
            <Row justify="center" className="mt-12 w-100 text-center">
              <span>{getText()?.GeneralInformation?.bySigning}</span>
              <CustomButton
                text="terms and conditions"
                className={`${styles["height-23"]} font-bold-base lh-sm ms-2`}
                disabled={false}
                theme="link"
                href="/terms-and-conditions"
              />
            </Row>
          </Form>
        ) : (
          <>
            <Row className={styles.congratulationsSection}>
              <span className={`mb-14`}>
                <Icon
                  name="check"
                  width={windowWidth > 992 ? 96 : 59}
                  height={windowWidth > 992 ? 96 : 59}
                  color="#076DDC"
                />
              </span>
              <span className={`${styles.congratulations} lh-sm `}>
                {getText()?.GeneralInformation?.congratulations}
              </span>
              <span
                className={`${styles.completedProfileMessage} font-regular-base lh-lg`}
              >
                {getText()?.GeneralInformation?.completedProfileMessage}
              </span>
              <span className={`${styles.updateOrChangeInformation} lh-lg`}>
                <span>
                  {getText()?.GeneralInformation?.updateOrChangeInformation}
                </span>
                <CustomButton
                  text="My Account"
                  disabled={false}
                  theme="link"
                  className={`${styles.btn} font-bold-sm lh-sm ms-2`}
                  onClick={() => {
                    window.location.href = "/account/accountInfo";
                  }}
                />
              </span>
              <span className={`${styles.applyForCreditLine} lh-lg`}>
                <span>{getText()?.GeneralInformation?.applyForCreditLine}</span>
                <CustomButton
                  text="Credit app section."
                  disabled={false}
                  theme="link"
                  href={process.env.NEXT_PUBLIC_CREDIT_LINE_APP_LINK}
                  target="_blank"
                  className={`${styles.btn} font-bold-sm lh-sm ms-2`}
                />
              </span>
              <CustomButton
                className={styles.continueShopping}
                theme="primary"
                disabled={false}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <span className="font-bold-base">
                  {getText()?.GeneralInformation?.continueShopping}
                </span>
              </CustomButton>
            </Row>
          </>
        )}
      </Col>
      {isModalOpen && (
        <Modal
          className={styles.HaggleModal}
          footer={false}
          width={"100%"}
          open={isModalOpen}
          centered
          onCancel={() => setIsModalOpen(false)}
        >
          <HaggleTable {...haggleTableProps} modal={true} />
        </Modal>
      )}
    </Row>
  ) : (
    <DataLoader size="large" className={styles.dataLoader} />
  );
};

export default GeneralInformation;
