import { useState, useEffect, useContext } from "react";
import { Tabs, notification, Modal, Button } from "antd";
import Icon from "~/src/components/shared/Icon";
import ACHPayments from "./ACHPayments";
import CreditCards from "./CreditCards";
import styles from "./styles.module.scss";
import { AppContext } from "~/src/libs/context";
import useCreditLines from "~/src/utilities/hooks/useCreditLines";
import DataLoader from "~/src/components/shared/DataLoader";
import CreditLines from "./CreditLines";
import CreditCardType from "./CreditCardType";
import { getText } from "~/src/libs/resources";
// import Link from "next/link";

const PaymentMethods = () => {
  const {
    error,
    loading,
    clean,
    addNewCreditCard,
    deleteCard,
    getDefaultCreditCard,
    getCreditLineByType,
    setCardAsDefault,
    CREDIT_LINES_TYPES,
  } = useCreditLines();
  const [activeKey, setActiveKey] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const { creditCardList, customerStatus } = useContext<any>(AppContext);
  const creditsCards = getCreditLineByType(CREDIT_LINES_TYPES.card);
  const achCards = getCreditLineByType(CREDIT_LINES_TYPES.ACH);
  const ePalletLineCards = getCreditLineByType(CREDIT_LINES_TYPES.ePalletLine);
  const isPalletActive =
    ePalletLineCards?.length &&
    ePalletLineCards?.find((item: CreditCardType) => item.is_verified === true);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!activeKey && creditCardList?.length > 0) {
      const defaultCard = getDefaultCreditCard();
      setActiveKey(defaultCard?.payment_method);
    }
    if (!activeKey && creditCardList?.length === 0) {
      setActiveKey(CREDIT_LINES_TYPES?.card);
    }
  }, [creditCardList]);

  useEffect(() => {
    if (error?.hasError) {
      notification.error({
        message: error?.message,
        duration: 4,
      });
      clean();
    }
  }, [error]);

  const callbackTabClicked = async (key: string) => {
    if (key === CREDIT_LINES_TYPES.ePalletLine && !ePalletLineCards?.length) {
      setLoadingState(true);
      try {
        setLoadingState(false);
      } catch (error) {}
    } else {
      setActiveKey(key);
    }
  };

  // interface TabItem {
  //   label: JSX.Element;
  //   key: string;
  //   children: JSX.Element;
  //   disabled: boolean;
  // }

  // const tabsItems: TabItem[] = [];
    // {
      // label: (
      //   <span className={styles.tabsTitle}>
      //     <Icon name="credit-card" width={46} height={46} />
      //     <div className={styles.container}>
      //       <span className={styles.titles}>
      //         {getText()?.Account?.cardBalance}
      //       </span>
      //     </div>
      //   </span>
        
      // ),
      // key: CREDIT_LINES_TYPES.card,
      // children: (
      //   <CreditCards
      //     creditCards={creditsCards}
      //     addCreditCard={addNewCreditCard}
      //     deleteCreditCard={deleteCard}
      //     setCardAsDefault={setCardAsDefault}
      //     defaultCard={getDefaultCreditCard()}
      //     loading={loading}
      //   />
      // ),
      // disabled: !customerStatus?.email_verified,
    // },
  // ];


  const tabsItems = [
    {
      label: (
        <span className={styles.tabsTitle}>
          <Icon name="credit-card" width={46} height={46} />
          <div className={styles.container}>
            <span className={styles.titles}>
              {getText()?.Account?.cardBalance}
            </span>
          </div>
        </span>
      ),
      key: CREDIT_LINES_TYPES.card,
      children: (
        <CreditCards
          creditCards={creditsCards}
          addCreditCard={addNewCreditCard}
          deleteCreditCard={deleteCard}
          setCardAsDefault={setCardAsDefault}
          defaultCard={getDefaultCreditCard()}
          loading={loading}
        />
      ),
      disabled: !customerStatus?.email_verified,
    },
  ];


  if (
    (customerStatus?.is_access_new_ach || achCards?.length) &&
    !tabsItems.find(({ key }) => key === CREDIT_LINES_TYPES.ACH)
  ) {
    tabsItems.push({
      label: (
        <span className={styles.tabsTitle}>
          <div className={styles.achimg}>
            <Icon name="bankIcon" width={46} height={46} />
          </div>
          <div className={styles.container}>
            <span className={styles.titles}>
              {getText()?.Account?.achPayments}
            </span>
          </div>
        </span>
      ),
      key: CREDIT_LINES_TYPES.ACH,
      children: (
        <ACHPayments
          cards={achCards}
          addBankAccount={addNewCreditCard}
          deleteBankAccount={deleteCard}
          setCardAsDefault={setCardAsDefault}
          defaultCard={getDefaultCreditCard()}
        />
      ),
      disabled: !customerStatus?.email_verified,
    });
  }

  tabsItems.push({
    label: (
      <>
        {ePalletLineCards?.length ? (
          <span className={styles.tabsTitle}>
            <Icon name="credit-line" width={46} height={46} />
            <div className={styles.container}>
              <span className={styles.titles}>
                {getText().checkout.creditLine}
              </span>
              <span className={styles.palletActive}>
                {isPalletActive && "Active"}
              </span>
            </div>
          </span>
        ) : (
          <a
            className={`${styles.creditLineLink} text-oxfordBlue`}
            onClick={showModal}
          >
            <span className={styles.tabsTitle}>
              <Icon name="credit-line" width={46} height={46} />
              <div className={styles.container}>
                <span className={styles.titles}>
                  {getText().checkout.applyForCreditLine}
                </span>
                <span className={styles.content}>
                  {getText()?.Account?.approveCreditLineMessage}
                </span>
                <span className={styles.palletActive}>
                  {isPalletActive && "Active"}
                </span>
              </div>
            </span>
          </a>
        )}
      </>
    ),
    key: CREDIT_LINES_TYPES.ePalletLine,
    children: (
      <CreditLines
        cards={ePalletLineCards}
        defaultCard={getDefaultCreditCard()}
        setCardAsDefault={setCardAsDefault}
      />
    ),
    disabled: !customerStatus?.email_verified,
  });

  return (
    <>
      <div className={styles.paymentMethods}>
        <div className={styles.title}>
          {getText()?.Account?.paymentMethodsTitle}
        </div>
        {loadingState && (
          <DataLoader className={`${styles.loader} py-5`} size="large" />
        )}
        <div className={`${loadingState && styles.cardsLoadingWrapper}`}>
          <Tabs
            type="card"
            activeKey={activeKey}
            items={tabsItems}
            onTabClick={callbackTabClicked}
            className={styles.tabsContainer}
          />
        </div>
      </div>
      <Modal
        title="Credit Application"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <p>
          In order to process your order, please download and fill out the credit application. Once completed, email your form to{" "}
          <a href="mailto:onlinesupport@la-foods.com" style={{ color: 'blue', textDecoration: 'underline' }}>
            onlinesupport@la-foods.com
          </a>.
        </p>
        <a href="/files/form_application.pdf" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
          Click here -Download Credit Application
        </a>
      </Modal>
    </>
  );
};

export default PaymentMethods;
